import "./App.css";
import React, { useState } from "react";
import MyHeader from "./Components/Pages/MyHeader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Components/Pages/Home";

import Privacy from "./Components/Essentials/Privacy";
import Imprint from "./Components/Essentials/Imprint";

import p404 from "./Components/Pages/404";

import { ThemeProvider } from "./ThemeProvider";

function App() {
  function WebPage() {
    return (
      <ThemeProvider>
        <Router>
          <div className="homeSplit">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/Privacy" component={Privacy} />
              <Route path="/Imprint" component={Imprint} />
              <Route path="*" status={404} component={p404} />
            </Switch>
            <MyHeader />
          </div>
        </Router>
      </ThemeProvider>
    );
  }
  return <div className="App">{WebPage()}</div>;
}

export default App;
