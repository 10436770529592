import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../../ThemeProvider";

const MyHeader = () => {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const location = useLocation();

  return (
    <div className="header">
      <div className="headerLinks">
        {location.pathname !== "/" && location.pathname !== "/privacy" && (
          <Link className="headerLink" to="/">
            Home
          </Link>
        )}
        {location.pathname !== "/imprint" && (
          <Link className="headerLink" to="/imprint">
            Imprint
          </Link>
        )}
        <Link className="headerLink" onClick={toggleTheme}>
          {isDarkTheme ? "Light Theme" : "Dark Theme"}
        </Link>
        {location.pathname !== "/privacy" && (
          <Link className="headerLink" to="/privacy">
            Privacy
          </Link>
        )}
        {location.pathname !== "/" && location.pathname !== "/imprint" && (
          <Link className="headerLink" to="/">
            Home
          </Link>
        )}
      </div>
      <div className="copyrightNotice">
        © {new Date().getFullYear()} Matteo Rosoli. All rights reserved.
      </div>
    </div>
  );
};

export default MyHeader;
